<script setup lang="ts">
import { MinusIcon, NearIcon, PlusIcon } from '@glow/shared/components/icons';
import type { Coords } from '@glow/shared/lib/coords';
import {
  MapMarkerLayer,
  renderMarkerWithImage,
  useMap,
} from '@glow/shared/lib/ol';

const props = defineProps<{
  markerLocation?: Coords;
}>();

const { staticUrl } = useRuntimeConfig().public;
const mapElementRef = ref<HTMLElement>();
const mapEvents = reactive<{
  onZoomChange?: (delta: 1 | -1) => void;
  onSearchNear?: () => void;
}>({});
const detectGeolocation = useDetectGeolocation();

useMap(mapElementRef, {
  onReady: (map) => {
    const markerLayer = new MapMarkerLayer(map, {
      renderer: () =>
        renderMarkerWithImage(`${staticUrl}/images/icons/work-place.svg`, '', {
          sizeFr: 0.6,
        }),
    });
    watch(
      () => props.markerLocation,
      (coords) => {
        if (!coords) return;
        markerLayer.setMarkers([{ coords }]);
        map.center = coords;
      },
      { immediate: true }
    );

    mapEvents.onZoomChange = (delta: 1 | -1) => {
      map.changeZoom(delta);
    };
    mapEvents.onSearchNear = async () => {
      const { coords } = await detectGeolocation();
      map.center = [coords.latitude, coords.longitude];
    };
  },
});
</script>

<template>
  <div class="simple-map">
    <div ref="mapElementRef" class="simple-map__map" @touchstart.stop />
    <div class="simple-map__zoom-buttons">
      <AppButton
        :icon-start="PlusIcon"
        icon-start-color="dark"
        border-radius="full"
        variant="secondary"
        color="light"
        class="simple-map__zoom-in-button shadow-sm d-none d-md-block"
        @click="mapEvents.onZoomChange?.(1)"
      />
      <AppButton
        :icon-start="MinusIcon"
        icon-start-color="dark"
        border-radius="full"
        variant="secondary"
        color="light"
        class="simple-map__zoom-out-button shadow-sm d-none d-md-block"
        @click="mapEvents.onZoomChange?.(-1)"
      />
    </div>
    <AppButton
      :icon-start="NearIcon"
      icon-start-color="dark"
      border-radius="full"
      variant="secondary"
      color="light"
      class="simple-map__near-button shadow-sm"
      @click="mapEvents.onSearchNear?.()"
    />
  </div>
</template>

<style scoped lang="scss">
.simple-map {
  --map-safe-zone: var(--app-spacing-3);

  position: relative;
  border-radius: var(--app-br-sm);
  overflow: hidden;
  height: 300px;

  &__map {
    width: 100%;
    height: 100%;
  }

  &__zoom-buttons {
    position: absolute;
    right: var(--map-safe-zone);
    bottom: var(--map-safe-zone);
    z-index: 7;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  &__near-button {
    position: absolute;
    top: var(--map-safe-zone);
    right: 16px;
    z-index: 7;
  }

  &__zoom-in-button,
  &__zoom-out-button,
  &__near-button {
    padding: 0.7em;
    font-size: 15px;
  }
}
</style>
